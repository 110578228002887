/** 简单地对 URL 进行解析 */
export function parseUrl(url: string) {
  const match = (url || '').match(
    /^(http|https|ftp)?(?:[\:\/]*)([a-z0-9\.-]*)(?:\:([0-9]+))?(\/[^?#]*)?(?:\?([^#]*))?(?:#(.*))?$/i,
  );
  const ret: any = new Object();

  if (!match) {
    return {};
  }

  ret.protocol = '';
  ret.host = match[2];
  ret.port = '';
  ret.path = '';
  ret.query = '';
  ret.fragment = '';

  if (match[1]) {
    ret.protocol = match[1];
  }

  if (match[3]) {
    ret.port = match[3];
  }

  if (match[4]) {
    ret.path = match[4];
  }

  if (match[5]) {
    ret.query = match[5];
  }

  if (match[6]) {
    ret.fragment = match[6];
  }

  return ret;
}

/** 获取当前 Url 中的参数 */
export function getUrlParamWithRegex(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

// https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getUrlParameterByName(name, _url?: string): string | undefined {
  const url =
    _url || (typeof window === 'undefined' ? '' : window.location.href);

  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/** 判断是否为本地域名 */
export function isLocalHost(str = '') {
  return (
    str.includes('localhost') ||
    str.includes('127.0.0.1') ||
    str.includes('0.0.0.0')
  );
}

export function getRedirectUrl(_url?: string): string | undefined {

  const url = _url || (typeof window === 'undefined' ? '' : window.location.href);

  const startIndex = url.indexOf('redirectTo=');
  if (startIndex !== -1) {
    const start = startIndex + 'redirectTo='.length;
    return decodeURIComponent(url.substring(start, url.length))
  }
  return null

}


export function removeUrlParameter(url, parameter) {
  // 使用 URL 对象解析 URL  
  let urlObject = new URL(url);

  // 使用 URLSearchParams 对象处理查询参数  
  let params = new URLSearchParams(urlObject.search);

  // 删除指定参数  
  params.delete(parameter);

  // 重新构建 URL  
  let cleanedUrl = `${urlObject.origin}${urlObject.pathname}`;

  // 添加 hash 部分（若有），并保证没有 '&' 符号在后面  
  if (urlObject.hash) {
    cleanedUrl += urlObject.hash.split('&')[0];
  }

  // 如果还有剩余参数，添加到 cleanedUrl  
  if (params.toString()) {
    cleanedUrl += '?' + params.toString();
  }

  return cleanedUrl;
}
